import React from 'react';
import { Box, Flex, Image, Menu, MenuButton, MenuList, MenuItem, Button } from '@chakra-ui/react';
import Styles from '../landingpage.module.css';
import Login from './Login';

const Header = () => {
  return (
    <Flex
      as="header"
      p={4}
      align="center"
      justify="space-between"
    >
      <Flex align="center">
        <a href='/'>
          <Image
            src="https://www.i95dev.com/wp-content/uploads/2020/08/i95dev-Logo-red.png"
            alt="i95dev Logo"
            w={'80px'}
            mr={4}
          />
        </a>
        
      </Flex>
      <Login />
    </Flex>
  );
};

export default Header;