import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom'; // Import useParams
import API from '../../../API/API';  // Assuming you have an API utility
import Styles from './Version.module.css';
import { Divider, SimpleGrid, Button, Stack, Heading, Card, CardBody, Box, Grid, GridItem, Image, CardFooter } from '@chakra-ui/react';
import Header from '../Header/Header';
import Select from 'react-select'
import Features from '../Features/Features';
import Prerequisites from '../Prerequisites/Prerequisites';
import { Spinner } from '@chakra-ui/react'
import Connectors from '../Connectors/Connectors';

const Versions = () => {
    const { id: connectorId } = useParams();
    const { name: connectorName } = useParams();
    
    const location = useLocation();
    const { imageContent } = location.state || {};
    const { index } = location.state || {};
    const { sourceShortName } = location.state || {};
    const { targetShortName } = location.state || {};





    const [versions, setVersions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    
    const [selectedSourceVersion, setSelectedSourceVersion] = useState(null);
    const [selectedTargetVersion, setSelectedTargetVersion] = useState(null);
    const [versionEntities, setVersionEntities] = useState(null);
    const [showProceed, setShowProceed] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [questionsLength, setQuestionsLength] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [connectorVersionId, setConnectorVersionId] = useState(null);
    const [next, setNext] = useState(0);
    
    const handleSourceChange = (selected) => {
        setSelectedSourceVersion(selected);
    };

    const handleTargetChange = (selected) => {
        setSelectedTargetVersion(selected);
    };

    useEffect(() => {
        API.get(`/MarketPlace/connectors/${connectorId}/endpoint-versions`,)
        .then((response) => {
            setVersions(response.data);
            console.log(response.data)
            setLoading(false)
        }).catch(error=>{
            setLoading(false)
        })
    }, [connectorId]);

    const sourceVersionOptions = [
        ...new Set(versions.map((item) => item.sourceVersion))
    ].map((version) => ({
        value: version,
        label: version
    }));
    
    const targetVersionOptions = [
        ...new Set(versions.map((item) => item.targetVersion))
    ].map((version) => ({
        value: version,
        label: version
    }));

    const confirm = async () => {
        if((selectedSourceVersion === null) || (selectedTargetVersion === null)){
            setErrorMessage('Please select Source and Target versions')
        }else{
            setNext(1)
            setErrorMessage(null)
            setLoading(true)
            const url = `/MarketPlace/connectors/${connectorId}/features`;
            const requestBody = {
                id: connectorId,
                sourceVersion: selectedSourceVersion.value,
                targetVersion: selectedTargetVersion.value,
            };
    
            try {
                const response = await API.post(url, requestBody, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                setVersionEntities(response.data[0]?.latestVersion?.versionEntities)
                setShowProceed(true)
            } catch (error) {
                console.error("Fetch error:", error);
            }
            API.get(`/MarketPlace/connectors/${connectorId}/version`,)
            .then(response=>{
                setConnectorVersionId(response.data)
                API.post(`/MarketPlace/connectors/${response.data}/questions`,)
                .then((response) => {
                    console.log(response.data)
                    setQuestions(response.data)
                    if(response.data.length > 0){
                        setQuestionsLength(response.data.length)
                    }else{
                        setQuestionsLength(0)
                    }
                    setLoading(false)
                }).catch(error=>{
                    setLoading(false)
                })
                setLoading(false)
            }).catch(error=>{
                setLoading(false)    
            })
            
        }
    }

    const VersionError = () =>{
        setErrorMessage('Please select Source and Target versions')
    }
    
    return (
        <>
            {
                loading && loading ? 
                <Box className={Styles.parentBoxofLoader}>
                    <Box w={'100%'} height={'100%'} position={'relative'}>
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                        position={'relative'}
                        top={'50%'}
                        transform={'translateY(-50%)'}
                        left={'50%'}
                    />
                    </Box>
                </Box>
                : null
            }
            <Header />
            {
                loading && loading ? 
                <Box className={'parentBoxofLoader'}>
                    <Box w={'100%'} height={'100%'} position={'relative'}>
                    <Box className={'spinnerLanding'}></Box>
                    </Box>
                </Box>
                : null
            }
            <Box className={Styles.mnContainer} mb={'15px'}>
                <Grid
                    templateAreas={`"nav main" "nav footer"`}
                    gridTemplateRows={'50px 1fr'}
                    gridTemplateColumns={'350px 1fr'}
                    h='auto'
                    gap='20px'
                    color='blackAlpha.700'
                    fontWeight='bold'
                >
                    <GridItem area={'nav'} border={'1px solid #e2e8f0'} className={Styles.Grid}>
                        <Card maxW={'350px'} borderBottom={'1px solid #e2e8f0'}>
                            <CardBody>
                                <Connectors 
                                    index={index}
                                    name={connectorName}
                                    image={imageContent}
                                    sourceShortName={sourceShortName}
                                    targetShortName={targetShortName}
                                />
                            </CardBody>
                        </Card>
                        <Card maxW={'350px'} mt={'20px'} className='versionBox'>
                            <CardBody>
                                <Box style={{ marginBottom: '20px' }}>
                                    <label>Source Version</label>
                                    <Select
                                        options={sourceVersionOptions}
                                        value={selectedSourceVersion}
                                        onChange={handleSourceChange}
                                        placeholder="Select Source Version"
                                    />
                                </Box>
                                <Box style={{ marginBottom: '20px' }}>
                                    <label>Target Version</label>
                                    <Select
                                        options={targetVersionOptions}
                                        value={selectedTargetVersion}
                                        onChange={handleTargetChange}
                                        placeholder="Select Target Version"
                                    />
                                </Box>
                                <Button className={Styles.Button} onClick={confirm}> Confirm </Button>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem area={'main'} border={'1px solid #e2e8f0'}>
                        <Card>
                            <CardBody>
                                <Box overflowY={'auto'}>
                                    <Features
                                        connectorName={connectorName}
                                        versionEntities={versionEntities}
                                    />
                                    {
                                        errorMessage && errorMessage !== null ? <Box className={Styles.warningMessage}> {errorMessage && errorMessage} </Box> : null
                                    }
                                </Box>
                                {
                                    next === 1 ? 
                                        <Prerequisites
                                            questions = {questions && questions}
                                            sourceVersion= {selectedSourceVersion && selectedSourceVersion.value}
                                            targetVersion= {selectedTargetVersion && selectedTargetVersion.value}
                                            versionError={VersionError}
                                            connectorVersionId={connectorVersionId}
                                        />
                                    :null
                                }
                            </CardBody>
                        </Card>
                    </GridItem>
                </Grid>
            </Box>
        </>
    );
};

export default Versions;
