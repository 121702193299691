import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import API from '../../../API/API';
import Styles from './Prerequisites.module.css';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    SimpleGrid,
    IconButton,
    Select,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

const FloatingLabelInput = ({ label, type, value, onChange, required, options }) => {
    const [showPassword, setShowPassword] = useState(false);
    
    const handleToggle = () => {
        setShowPassword(!showPassword);
    };

    return (
        <FormControl className={Styles.floatingLabelFormControl}>
            {type === 'Dropdown' ? (
                <Select
                    placeholder={label}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    isRequired={required}
                >
                    {options.map((option) => (
                        <option key={option.id} value={option.code}>
                            {option.displayName}
                        </option>
                    ))}
                </Select>
            ) : (
                <>
                    <Input
                        type={type === 'Password' && !showPassword ? 'password' : 'text'}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        required={required}
                        className={value ? Styles.hasValue : ''}
                    />
                    <FormLabel className={Styles.floatingLabel}>{label}</FormLabel>
                    {type === 'Password' && (
                        <IconButton
                            variant="link"
                            aria-label={showPassword ? 'Hide password' : 'Show password'}
                            icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                            onClick={handleToggle}
                            position="absolute"
                            right="0.5rem"
                            top="50%"
                            transform="translateY(-50%)"
                            color="gray.500"
                        />
                    )}
                </>
            )}
        </FormControl>
    );
};

const Prerequisites = (props) => {
    const history = useHistory();
    const { id: connectorId } = useParams();
    const { name: connectorName } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [formState, setFormState] = useState({
        email: '',
        name: '',
        companyName: '',
        password: '', // Include password state
    });
    const [subscriptionAnswers, setSubscriptionAnswers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({}); // Validation errors

    // Initialize form state with default values from props.questions
    useEffect(() => {
        const initialState = {
            email: '',
            name: '',
            companyName: '',
            password: '',
        };

        // Populate initialState with default values from questions
        props.questions.forEach((question) => {
            if (question.defaultValue) {
                initialState[question.code] = question.defaultValue;
            }
        });

        setFormState(initialState);
    }, [props.questions]);

    const confirmProceed = () => {
        if (props.sourceVersion === null || props.targetVersion === null) {
            props.versionError();
        } else {
            onOpen();
        }
    };

    // Handle input change including dynamic fields
    const handleInputChange = (code, value, questionId = null) => {
        setFormState({
            ...formState,
            [code]: value,
        });

        // If it's a dynamic question, store in SubscriptionAnswers
        if (questionId) {
            setSubscriptionAnswers((prevAnswers) => {
                const existingAnswerIndex = prevAnswers.findIndex(answer => answer.QuestionId === questionId);
                if (existingAnswerIndex !== -1) {
                    // Update existing answer
                    const updatedAnswers = [...prevAnswers];
                    updatedAnswers[existingAnswerIndex].Answer = value;
                    return updatedAnswers;
                } else {
                    // Add new answer
                    return [...prevAnswers, { QuestionId: questionId, Answer: value }];
                }
            });
        }
    };

    // Reset the form state
    const resetForm = () => {
        setFormState({
            email: '',
            name: '',
            companyName: '',
            password: '', // Reset password state
        });
        setSubscriptionAnswers([]);
        setError('');
        setErrors({});
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    // Form validation logic
    const validateForm = () => {
        let validationErrors = {};

        // Validate Email, Name, CompanyName
        if (!formState.email) {
            validationErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formState.email)) {
            validationErrors.email = 'Email is invalid';
        }

        if (!formState.name) {
            validationErrors.name = 'Name is required';
        }

        if (!formState.companyName) {
            validationErrors.companyName = 'Company Name is required';
        }

        // Validate dynamic fields from props.questions
        props.questions.forEach((field) => {
            const answer = subscriptionAnswers.find(a => a.QuestionId === field.id);
            
            if (field.validations && field.validations.required && (!answer || !answer.Answer)) {
                validationErrors[field.code] = `${field.text} is required`;
            }

            // Additional validation based on question type can be added here
            if (field.type === 'Text' && answer && answer.Answer.trim() === '') {
                validationErrors[field.code] = `${field.text} cannot be empty`;
            }
        });

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    // Submit handler
    const handleSubmit = async () => {
        if (!validateForm()) return;

        const body = {
            ConnectorVersionId: parseInt(props.connectorVersionId),
            ConnectorName: connectorName,
            SourceVersion: props.sourceVersion,
            TargetVersion: props.targetVersion,
            Email: formState.email,
            Name: formState.name,
            CompanyName: formState.companyName,
            Password: formState.password, // Include password in the request body
        };
        
        if (subscriptionAnswers.length > 0) {
            body.SubscriptionAnswers = subscriptionAnswers;
        }

        setLoading(true);
        setError('');

        try {
            const response = await API.post('/Subscription/TrialSubscription', body, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                history.push('/subscription');
            } else {
                console.error('Error submitting the form:', response.statusText);
            }
        } catch (error) {
            setError('There was an error submitting the form.');
            console.error('Error during API call:', error);
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    return (
        <>
            {loading && (
                <Box className={Styles.parentBoxofLoader}>
                    <Box w={'100%'} height={'100%'} position={'relative'}>
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='xl'
                            position={'relative'}
                            top={'50%'}
                            transform={'translateY(-50%)'}
                            left={'50%'}
                        />
                    </Box>
                </Box>
            )}
            <Button className={Styles.Button} onClick={confirmProceed}>
                Provide Prerequisites
            </Button>

            {/* Modal */}
            <Modal isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent maxW={'750px'}>
                    <ModalHeader>Provide Prerequisites</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                            <SimpleGrid columns={3} spacing={4}>
                                {/* Static Fields: Email, Name, CompanyName */}
                                <FloatingLabelInput
                                    label="Email"
                                    type="email"
                                    value={formState.email}
                                    onChange={(value) => handleInputChange('email', value)}
                                    required
                                />
                                {errors.email && <span className={Styles.error}>{errors.email}</span>}

                                <FloatingLabelInput
                                    label="Name"
                                    type="text"
                                    value={formState.name}
                                    onChange={(value) => handleInputChange('name', value)}
                                    required
                                />
                                {errors.name && <span className={Styles.error}>{errors.name}</span>}

                                <FloatingLabelInput
                                    label="Company Name"
                                    type="text"
                                    value={formState.companyName}
                                    onChange={(value) => handleInputChange('companyName', value)}
                                    required
                                />
                                {errors.companyName && <span className={Styles.error}>{errors.companyName}</span>}

                                {/* Password Field */}
                                <FloatingLabelInput
                                    label="Password"
                                    type="Password"
                                    value={formState.password}
                                    onChange={(value) => handleInputChange('password', value)} // Set password state
                                    required
                                />
                                {errors.password && <span className={Styles.error}>{errors.password}</span>}

                                {/* Dynamic Fields: Render based on questions prop */}
                                {props.questions.map((question) => (
                                    <FloatingLabelInput
                                        key={question.id}
                                        label={question.text}
                                        type={question.type}
                                        value={subscriptionAnswers.find(a => a.QuestionId === question.id)?.Answer || ''}
                                        onChange={(value) => handleInputChange(question.code, value, question.id)} // Pass question ID
                                        options={question.questionOptions}
                                    />
                                ))}
                            </SimpleGrid>
                            <Button
                                mt={4}
                                colorScheme='blue'
                                type="submit"
                                isLoading={loading} // Show loading state on button
                            >
                                Submit
                            </Button>
                            {error && <span className={Styles.error}>{error}</span>}
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default Prerequisites;
