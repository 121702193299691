import React from 'react'
import Styles from './Subscription.module.css'
import Header from '../Header/Header'
import Login from '../Header/Login';
import { Spinner } from '@chakra-ui/react'
import { Divider, SimpleGrid, Button, Text, Heading, Card, CardBody, Box, Grid, GridItem, Image, CardFooter } from '@chakra-ui/react';
const Subscription = () => {
    return (
        <>
            <Header />
            <Card>
                <CardBody textAlign={'center'}>
                    <Heading> <Text as={'span'} color={'#1935c1'}> "Thank you </Text><Text as={'span'} color={'#dc332e'}> for subscribing!" </Text> </Heading>
                    <Text className={Styles.bodyMessage}>
                        We appreciate your interest, 
                        We’ve sent a email please follow the instructions in the email. 
                        If you don’t see it, kindly check your spam or junk folder. 
                        Still no email? please login and continue.
                    </Text>
                    <Login />
                </CardBody>
            </Card>
        </>
    )
}

export default Subscription
