import React from 'react';
import Styles from './Login.module.css'
import { Container, Card, CardBody, SimpleGrid, Grid, GridItem, Button, Box } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';

const Login = () => {
    const handleLogin = async () => {
        try {
            // Redirect to federated sign-in page
            await Auth.federatedSignIn();
        } catch (error) {
            console.error('Error during sign-in:', error);
        }
    };

    return (
        <div className="login-container">
            <Button className={Styles.LoginButton} onClick={handleLogin}>Login</Button>
        </div>
    );
};

export default Login;